<template>
  <div>
    <div v-if="!isEdit" class="text-truncate" :class="{ 'mb-3': !lastItem }">
      {{ url }}
    </div>

    <div v-else>
      <TextField
        ref="url"
        showDeleteBtn
        label="URL"
        :value.sync="_value"
        :showAddBtn="firstItem"
        :class="{ 'mb-2': !lastItem }"
        @click:add="$emit('click:add')"
        @click:delete="$emit('click:delete')"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TextField from "@/contact/views/components/list/common/TextField.vue";

export default {
  components: { TextField },
  props: {
    url: {
      type: String,
      default: ""
    },
    firstItem: {
      type: Boolean,
      default: false
    },
    lastItem: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit"]),
    _value: {
      get() {
        return this.url;
      },
      set(url) {
        this.$emit("update:value", url);
      }
    }
  },
  methods: {
    focus() {
      this.$refs.url?.$refs?.textfield?.focus();
    }
  }
};
</script>
