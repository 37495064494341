<template>
  <div class="cr-card-detail-option" v-if="show">
    <v-icon>
      mdi-link
    </v-icon>

    <div class="cr-edit-wrapper" :class="{ 'py-2': isEdit }">
      <Homepage
        ref="homepage"
        v-for="(url, idx) in urlList"
        :key="idx"
        :url="url"
        :firstItem="idx === 0"
        :lastItem="idx + 1 === urlList.length"
        @update:value="v => $set(urlList, idx, v)"
        @click:add="addHomepage"
        @click:delete="$delete(urlList, idx)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>

<script>
import { mapGetters } from "vuex";
// import { isBlank } from "@/commons/utils/validation";
import Homepage from "./Homepage.vue";

export default {
  components: { Homepage },
  data({ $store: { getters } }) {
    let { urlList } = getters["customerCardDetail/detail"];
    urlList = [...(urlList || [])];

    if (!urlList.length) return { urlList: [] };
    return { urlList };
  },
  computed: {
    ...mapGetters("customerCardDetail", ["isEdit", "detail"]),
    show() {
      return true;
      /*
      if (this.isEdit) return true;
      if (this.urlList.length === 0) return false;
      if (this.urlList.length === 1 && isBlank(this.urlList[0])) return false;
      return this.urlList.length > 0;
       */
    }
  },
  watch: {
    isEdit() {
      if (this.isEdit) return;

      let { urlList } = this.detail;
      urlList = [...(urlList || [])];
      if (!urlList.length) urlList = [""];

      this.$set(this, "urlList", urlList);
    }
  },
  mounted() {
    let { urlList } = this.detail;
    urlList = [...(urlList || [])];
    if (!urlList.length) urlList = [""];

    this.$set(this, "urlList", urlList);
  },
  methods: {
    addHomepage() {
      this.urlList = [...this.urlList, ""];
      this.$nextTick(() => {
        const idx = this.urlList.length - 1;
        this.$refs.homepage[idx]?.focus();
      });
    },
    getData() {
      if (!this.isEdit) return;
      return { urlList: this.urlList };
    }
  }
};
</script>
